import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Products from "../components/Products";
import SEO from "../components/SEO";

const ToejPage = () => {
  const data = useStaticQuery(graphql`
    {
      allSitePage(filter: { context: { productType: { eq: "toej" } } }) {
        edges {
          node {
            context {
              productTitle
              productImage
              productPath
              productPrice
              productOldprice
              productLink
              productId
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        title="Find tilbud på træningstøj"
        description="Spar en masse penge og find de bedste priser på træningstøj hos os. Det behøver ikke at være dyrt, at komme i form."
      />
      <h1 className="font-bold text-xl">Træningstøj</h1>
      <p className="max-w-xl pb-4">
        Gør det nemt at stå skarpt! Med mærker såsom Better Bodies, Gold Gym og
        Bodylab t-shirts, kan du opnå den mest behagelige træning, og ikke noget
        med tøj der irriterer. Samtidig sidder det tæt på kroppen, og kan være
        med til at fremhæve dine muskler.
      </p>
      <Products data={data} />
    </>
  );
};

export default ToejPage;
